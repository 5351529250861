
import {request} from "@/network/request.js";
import qs from 'qs';

export default{
  //查询-条件查询商品销量汇总信息
  findProductSalesReport(data){
    return request({
      method:'POST',
      url:'/report/productSales/findProductSalesReport',
      data
    })
  },
  // 查询-条件商品销量统计列表信息
  findProductSalesReportList(data){
    return request({
      method:'POST',
      url:'/report/productSales/findProductSalesReportList',
      data
    })
  },
  // 查询-产品列表
  findProductSalesList(data){
    return request({
      method:'POST',
      url:'/report/productSales/findProductSalesList',
      data
    })
  },
  // 查询-产品列表
  findAllProductManager(data){
    return request({
      method:'POST',
      url:'/datainfo/findAllProductManager',
      data
    })
  },
  // 查询-条件查询商品销量-销量趋势统计列表信息
  findProductSalesTrendReportList(data){
    return request({
      method:'POST',
      url:'/report/productSales/findProductSalesTrendReport',
      data
    })
  },
}
